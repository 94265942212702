@import "~@/assets/scss/global.scss";





































































































































.page-top {
  padding: 6px 14px 0 14px;
}
.page-table{
    text-align: right;
    padding: 0 14px 0 14px;
}
.copy-content {
    margin-left: 10px;
    padding: 5px 10px !important;
}
